/*Login Styles*/
.login-container {
    border: 1px solid black;
    background-color: #fff;
    max-width: 790px;
    margin: auto;
    margin-top: 75px;
}

.h-75vh {
    height: 75vh;
}

.full-width {
    width: 100%;
}

.left-align {
    text-align: left;
}

.right-float {
    float: right;
}

a {
    font-size: 14px;
    color: #0c7c84;
}

    a:hover {
        text-decoration: none;
        color: #16b9d4;
        cursor: pointer;
    }

    a:not([href]):not([tabindex]) {
        font-size: 14px;
        color: #0c7c84;
        text-decoration: unset;
    }
        a:not([href]):not([tabindex]):hover {
            text-decoration: none;
            color: #16b9d4;
            cursor: pointer;
        }

    .top-header {
        height: 53px;
        padding: 16px;
        font-size: 24px;
        font-weight: 300;
        line-height: 18px;
        background: #424242;
        color: #fcfcfc;
    }

.btnRightContainer {
    align-self: flex-end;
    float: right;
    margin-bottom: 15px;
    margin-right: 30px;
}

.left-panel-login {
    height: 285px;
    width: 314px;
    margin: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.right-panel-login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    min-height: 268px;
    width: 100%;
}

.right-contents {
    height: 205px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 20px;
    font-family: Roboto,Helvetica Neue,Helvetica,Arial,sans-serif;
    color: #616161;
}

@media screen and (max-width: 767px) {
    .divider {
        display: none;
    }

    .left-panel-login {
        display: none;
    }

    .right-contents {
        padding: 20px;
    }
}

.btn {
    background-color: #0c7c84;
    background-image: none;
    color: #fff;
    border-color: #0c7c84;
    font-weight: 600;
    margin: 15px;
}

    .btn:hover {
        background-color: #16b9d4;
        background-image: none;
        color: #fff;
        border-color: #16b9d4;
    }
