.mediumHeaderText {
    font-weight: 600;
    font-size: small;
}

.green {
    color: green !important;
}

.yellow {
    color: yellow !important;
}

.red {
    color: red !important;
}

.tableLoading {
    text-align: center;
    margin: auto;
    padding-top: 45px;
}