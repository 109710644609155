.rich-editor {
    position: relative;
    margin: 10px 18px 37px;
    
    background-color: white;
}

.text-area {
    border: .5px solid #eee;
    padding: 5px;
    min-height: 10em;
}

.css-26va8x {
    z-index: 999;
}

.activityDiv {
    background-color: #fff;
}

.activityDiv p, .activityDiv p pre {
    margin: 0 !important;
}

.inputName {
    line-height: 18px;
    font-weight: bold;
    color: #16b9d4;
}

.fontSize14{
    font-size: 14px;
}

.activityText{
    margin: 5px 10px;
}

.individualActivity {
    padding: 5px 10px;
    border-bottom: 1px dotted#eee;
}

h3 {
    border-bottom: 2px solid #eee;
    padding: 10px 0;
}

.strongMention {
    color:#0c7c84;
}

.scrollable {
    overflow: auto;
    height: 500px;
    width: 100%;
}
