/* Please see documentation at https://docs.microsoft.com/aspnet/core/client-side/bundling-and-minification
for details on configuring this project to bundle and minify static web assets. */

a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

.fontSize20 {
    font-size: 20px !important;
}

.greenHeader {
    color: #0c7c84;
    font-size: 25px;
    font-weight: 600;
}

/* Sticky footer styles
-------------------------------------------------- */
html {
    font-size: 14px;
}

body {
    /*background-color: #eaeaea;*/
    background-color: #fff;
}

.paddingBottom25 {
    padding-bottom: 25px;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

.border-top {
    border-top: 1px solid #e5e5e5;
}

.border-bottom {
    border-bottom: 1px solid #e5e5e5;
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

button.accept-policy {
    font-size: 1rem;
    line-height: inherit;
}

.marginTop10px {
    margin-top:10px;
}

.marginTop15px {
    margin-top: 15px;
}

.margin15px {
    margin: 15px !important;
}

.bottomMargin15 {
    margin-bottom: 15px;
}
/* Sticky footer styles
-------------------------------------------------- */
html {
    position: relative;
    min-height: 100%;
}

body {
    /* Margin bottom by footer height */
    margin-bottom: 60px;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    white-space: nowrap;
    /* Set the fixed height of the footer here */
    height: 60px;
    line-height: 60px; /* Vertically center the text there */
}

.nav-tabs {
    padding-left: 10%;
    background-color: #fafbfc;
}

.nav-item .nav-link {
    color: #495057 !important;
}

#myTab .nav-link i {
    color: rgba(27,31,35,.3) !important;
}

#myTab .nav-link .active i {
    color: #495057 !important;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    border-color: transparent;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    border-width: 3px 1px !important;
    background-color: #fff !important;
    border-color: #e36209 #e1e4e8 transparent !important;
}

.tab-content {
    padding: 25px 0;
}

/*.dropdown-menu {
    display: unset !important;
    padding: 0;
}*/

.align-right {
    text-align: right;
}

.details-reset > summary:focus {
    outline: none;
}

ul .Box {
    width: 300px;
}

ul .Box-header {
    padding: 5px;
}

ul .Box-row {
    padding: 5px 5px 5px 20px;
}

    ul .Box-row:hover {
        background-color: #0366d6;
        color: #fff;
        cursor: pointer;
    }

.react-date-picker__wrapper {
    display: -ms-flexbox;
    display: flex;
    border: none !important; 
}



.adminSearchDiv {
    background-color: #fafbfc;
    padding: 90px 0 0 0;
    border-bottom: 1px solid #e1e4e8;
    padding-bottom: 15px;
}

.tableContainer {
    padding-top: 15px;
}

    .tableContainer thead {
        background-color: #f6f8fa;
    }

    .tableContainer td {
        font-weight: 600;
    }

    .tableContainer td:hover {
        cursor: pointer;
    }

.Box-row:first-of-type {
    border-top-color: #e1e4e8;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}

.strongHover {
    font-weight: 600;
}

    .strongHover:hover {
        cursor: pointer;
    }

.pagination a {
    color: #0c7c84 !important;
}

.pagination .current {
    color: #fff !important;
    background-color: #0c7c84 !important;
}

.pagination .disabled {
    color:#d1d5da !important;
}

.disabled:hover {
    cursor: unset !important;
}

.clickableRow:hover{
    cursor: pointer;
}

.projectDetailsSubmitBtn {
    font-size: 11px;
    float:right;
}

.fa-trash-alt{
    font-size: xx-large;
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__input-container {
    width: 100%;
}

#projectDetailsCancelLink {
    margin: 15px;
    padding: 0.375rem 0.75rem;
}

.aboveBox {
    margin-bottom: 15px;
}

.tabPanelDiv {
    margin-bottom: 50px;
}

#addTaskLink {
    text-align: right;
    display: block;
    margin-bottom: 15px
}

.alignRight {
    text-align:right;
    display: block;
}

section {
    margin: 10px 0;
}

.row {
    margin: unset !important;
}

.autoMargin {
    margin: auto !important;
}

.react-tags__search-input {
    width: 100% !important;
}

.floatRight{
    float: right !important;
}

.scrollableDiv {
    overflow-y: scroll;
    display: block;
    height: calc(100vh - 91px);
}

.secureDocsScrollableDiv {
    overflow-y: scroll;
    display: block;
    height: calc(100vh - 324px);
}


@media (max-width: 991px) {
    .scrollableDiv, .secureDocsScrollableDiv {
        overflow-y: unset !important;
        display: block;
        height: unset !important;
    }
}

.dropzoneContainer {
    border: 2px dashed rgba(27,31,35,.3) !important;
    padding: 3rem 0;
    margin: 0;
    width: 100% !important;
    font-size: larger;
    font-weight: 600;
    color: rgba(27,31,35, .5);
}

.dropzoneContainer:hover {
    cursor: pointer;
}

.paperclip-lg {
    font-size: 30px;
    margin: 10px;
}

.paperclip {
    color: rgba(27,31,35, .3);
    margin: 5px;
}

.trashHover {
    display: none;
}

.trashHover .fa-trash-alt {
    font-size: large;
}

.fileLinkContainer:hover .trashHover {
    display: block;
    float: right;
    
}

.fileLinkContainer {
    padding: .5rem;
    margin: unset;
}

.fileLinkContainer:hover {
    border: 1px solid rgba(27,31,35,.3);
    cursor: pointer;
    border-radius: 5px;
}

.fileLink {
    font-size: 16px !important;
}

.paddingTop90 {
    padding-top: 90px;
}

.paddingTop25 {
    padding-top: 25px;
}

.secureDropzoneContainer {
    margin: 0 auto;
    width: 75% !important;
}

.marginBottom30 {
    margin-bottom: 30px;
}

.fontSize11 {
    font-size: 11px;
}

.projectTitle {
    font-size: x-large;
    font-weight: 400;
}

.centerAlign {
    text-align: center;
}

.width100 {
    width: 100% !important;
}

.colorRed {
    color: red;
}

.SpreadsheetGrid__headCell {
    background-color: #f6f8fa !important;
    color: black !important;
}

.SpreadsheetGrid__header {
    list-style-type: none;
    border-top: 1px solid #e1e4e8;
    border-right: 1px solid #e1e4e8;
    border-left: 1px solid #e1e4e8;
}

.listCategoriesContainer {
    border: 1px solid #ced4da;
    overflow: auto;
}

.listCategoriesBtn {
}

    .listCategoriesBtn:hover {
        background-color: #0c7c84;
        color: white;
        cursor: pointer;
    }

.activeListCategories {
    background-color: #0c7c84;
    color: white;
}