a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.fa-user {
    color: #fff !important;
    font-size: large;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    right: 0 !important;
    left: unset !important;
}

.hover:hover {
    cursor: pointer;
}

.navbar-nav {
    align-items: center;
    display: flex;
}

.navbar {
    justify-content: space-between !important;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
}

.margin5 {
    margin: 5px 5px !important;
}

.header-row {
    display: flex;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    max-height: 100%;
}

.header-column {
    display: flex;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-direction: column;
    flex-direction: column;
}

.header-body {
    width: 100%;
}

.noFlexGrow {
    flex-grow: unset !important;
}

nav ul {
    line-height: unset !important;
}

.nav-item .nav-link, #collasible-nav-dropdown {
    color: rgba(255, 255, 255, 0.5) !important;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    /* color: #fff; */
    background-color: unset !important;
}

#hamburger {
    display: none ;
}

.displayNavContents {
    display: flex;

}

@media screen and (max-width: 1250px) {
    #hamburger {
        display: block;
    }

    .displayNavContents {
        display: none !important;
    }
}

@media screen and (max-width: 564px) {
    .navbar-brand {
        display: none;
    }
}

.dropdown-menu.show {
    transform: translate3d(0px, 60px, 0px) !important;

}

.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    background-color: rgba(72, 72, 72, .7);
    overflow-x: hidden;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    padding-top: 50px;
}

.show2 {
    width: 250px !important;
}

.sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: white;
    display: block;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    font-style: normal;
    font-weight: 400;
}

.black-font {
    color: white !important;
    text-shadow: 1px 3px 8px rgba(0, 0, 0, 0.8);
    font-size: 19px !important;
    font-weight: 700;
}
    .black-font:hover {
        cursor: pointer;
        -webkit-transform: translateY(-3px);
        -ms-transform: translateY(-3px);
        transform: translateY(-3px);
    }

.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
    color: white !important;
}

.paddingSideNav {
    padding: 8px 8px 8px 32px;
}

.color-orange {
    color: orange !important;
}

.color-orange:hover {
    color: #ffd27f !important;
}

.orangeHighlight {
    background-color: orange;
    color: #343a40 !important;
    border-radius: 5px;
}

.dropdown-toggle::after {
    display: none;
}

.companyInNav::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}